export const banglaInsights = [
  {
    heading:
      "Bangladesh is witnessing rapid increase in rural to urban migration",
    text: `There has been a large increase in rural to urban migration in Bangladesh in the last 15 years.<sup><a href="http://bbs.portal.gov.bd/sites/default/files/files/bbs.portal.gov.bd/page/b2db8758_8497_412c_a9ec_6bb299f8b3ab/2021-08-11-04-54-154c14988ce53f65700592b03e05a0f8.pdf" target="_blank" rel="noreferrer" >[11]</a></sup> This has been driven by urbanisation and the associated boom in the manufacturing sector. Rise in ready-made garment factories has fuelled migration among women<sup><a href="https://www.researchgate.net/publication/259547142_Feminization_and_Labor_Vulnerability_in_Global_Manufacturing_Industries_Does_Gendered_Discourse_Matter" target="_blank" rel="noreferrer" >[12]</a></sup>, who make up 80% of the employees in the sector.<sup><a href="https://waronwant.org/sites/default/files/Stitched%20Up.pdf" target="_blank" rel="noreferrer" >[13]</a></sup>`,
    image: "/images/bangladesh/insight-1.jpg",
  },
  {
    heading:
      "Climate change – both slow-onset and extreme events – are fuelling rural to urban migration in Bangladesh :",
    text: `In 2019, climate related impacts led to the displacement of 4.1 million people, and migration of 455,941 mainly due to slow-onset climate change within Bangladesh.<sup><a href="https://www.internal-displacement.org/publications/2020-global-report-on-internal-displacement#:~:text=The%20Global%20Report%20on%20Internal,in%20addressing%20and%20preventing%20displacement" target="_blank" rel="noreferrer" >[14]</a></sup> Further predictions suggest by 2050, the country could have 13.3 million internal climate migrants<sup><a href="https://openknowledge.worldbank.org/entities/publication/2be91c76-d023-5809-9c94-d41b71c25635" target="_blank" rel="noreferrer" >[15]</a></sup>, accounting for a third of all in South Asia.<sup><a href="https://openknowledge.worldbank.org/entities/publication/2c9150df-52c3-58ed-9075-d78ea56c3267" target="_blank" rel="noreferrer" >[16]</a></sup> Major push factors for climate migration are associated with the lack of productivity in agriculture during lean seasons, annual flooding and recurrent food crises. These continue to push vulnerable communities such as farmers and landless labourers in distress to migrate to other regions of Bangladesh, increasing internal migration.<sup><a href="https://direct.mit.edu/adev/article/37/1/1/93293/Seasonal-Poverty-and-Seasonal-Migration-in-Asia" target="_blank" rel="noreferrer" >[17]</a></sup> For example, the northern region is faced with a recurring seasonal event, commonly referred to as ‘monga’ which creates a near-famine situation between the periods of cultivation and harvest, leading to annual labour migration in the region.<sup><a href="https://rsa.tandfonline.com/doi/pdf/10.1080/17565529.2013.833078" target="_blank" rel="noreferrer" >[18]</a></sup>`,
    image: "/images/bangladesh/insight-2.jpg",
  },
  {
    heading:
      "Challenges directly linked to labour laws increase vulnerability of migrant workers:",
    text: `Migrant workers are often paid less and made to work more. For example, there exists a huge gap between the minimum wage and living wage, especially in Dhaka.<sup><a href="https://www.globallivingwage.org/wp-content/uploads/2018/05/Dhaka_Living_Wage_Benchmark_Report.pdf" target="_blank" rel="noreferrer">[19]</a></sup> The current minimum wage in Bangladesh is the lowest in the Asia and Pacific region excluding sectors in which migrant workers are employed such as agriculture and domestic work.<sup><a href="https://www.employment-studies.co.uk/system/files/resources/files/ILO%20Global%20Wage%20Report%202020-21.pdf" target="_blank" rel="noreferrer">[20]</a></sup> The data on the Labour Rights Index also shows that there is a total lack of decent work in the country, suggesting challenges in compliance with the existing labour laws.<sup><a href="https://labourrightsindex.org/lri-2022-documents/bangladesh.pdf" target="_blank" rel="noreferrer">[21]</a></sup> For instance, 50% of the workers in 18 ready-made garment factories exceeded the overtime limit.<sup><a href="https://www.fairlabor.org/projects/toward-fair-compensation-in-bangladesh/ " target="_blank" rel="noreferrer">[22]</a></sup>`,
    image: "/images/bangladesh/insight-3.jpg",
  },
  {
    heading:
      "Design of existing schemes makes it challenging for migrants to access benefits:",
    text: `There are over 142 social security schemes in Bangladesh.<sup><a href="https://publications.iom.int/system/files/pdf/bangladesh-_migof.pdf" target="_blank" rel="noreferrer">[23]</a></sup> However, migrants face challenges in accessing them, primarily due to lack of portability. This is because schemes are designed based on geographical area, making migrants ‘ineligible’ when they move to a different geography for work.<sup><a href="https://www.shram.org/uploadFiles/20170809013520.pdf" target="_blank" rel="noreferrer">[24]</a></sup>`,
    image: "/images/bangladesh/insight-4.jpg",
  },
];

{
  /* <sup><a href="" target="_blank" rel="noreferrer">[2]</a></sup> */
}
