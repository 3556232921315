import React from "react";
import Paragraph from "../../../utils/Paragraph";
import { AiFillCheckCircle } from "@react-icons/all-files/ai/AiFillCheckCircle";
import BangladeshMap from "./BangladeshMap";
import NepalMap from "./NepalMap";
export default function Banner() {
  const supClass =
    "text-white hover:text-blue transition-all duration-300 ease";
  return (
    <div className="bg-darkblue w-full overflow-hidden lg:min-h-[calc(100vh-5.5rem)] lg:h-auto lg:rounded-b-[80px] rounded-b-[20px] lg:px-28 md:px-12 px-4 flex items-center lg:py-0 py-0">
      <div className="grid lg:grid-cols-[1.25fr_0.75fr] grid-cols-1 auto-rows-auto lg:gap-12 gap-8 w-full h-full lg:py-0 py-8">
        <div className="w-full h-full flex flex-col justify-center">
          <h1 className="lg:text-[45px] md:text-3xl text-2xl text-white font-bold font-body [&>span]:text-[#19BBF9] !leading-tight">
            Internal Migration:
            <br /> <span>Factual Overview</span>
          </h1>
          <div className="relative grid grid-cols-1 auto-rows-min w-full gap-0 lg:mt-6 mt-4">
            <TextBox>
              <strong>No. of internal migrants</strong>: 13.7 million
              <sup>
                <a
                  href="http://bbs.portal.gov.bd/sites/default/files/files/bbs.portal.gov.bd/page/b2db8758_8497_412c_a9ec_6bb299f8b3ab/2021-08-11-04-54-154c14988ce53f65700592b03e05a0f8.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className={supClass}
                >
                  [1]
                </a>
              </sup>{" "}
              lifetime migrants
            </TextBox>
            <TextBox>
              66.2%
              <sup>
                <a
                  href="https://cabinet.gov.bd/sites/default/files/files/cabinet.portal.gov.bd/research_corner/9dbddde7_4354_4f17_a256_1a444156b1aa/Determinants%20of%20Internal%20Migration%20Evidence%20from%20Bangladesh.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className={supClass}
                >
                  [2]
                </a>
              </sup>{" "}
              of all migrants move from rural to urban areas in Bangladesh
            </TextBox>
            <TextBox>
              <strong>
                <u>Key destination areas:</u>
              </strong>
              <sup>
                <a
                  href="https://tuengr.com/V11A/11A11F.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className={supClass}
                >
                  [3]
                </a>
              </sup>{" "}
              Dhaka, Khulna, Rajshahi
            </TextBox>

            <TextBox>
              <strong>
                Prominent sectors of employment for internal migrants:
              </strong>{" "}
              Ready Made Garment sector
              <sup>
                <a
                  href="https://link.springer.com/chapter/10.1007/978-981-13-1537-4_12"
                  target="_blank"
                  rel="noreferrer"
                  className={supClass}
                >
                  [4]
                </a>
              </sup>{" "}
              (85% are internal migrants
              <sup>
                <a
                  href="http://eprints.lse.ac.uk/81677/1/Barriers%20to%20labour%20migration%20in%20Bangladesh%E2%80%99s%20garment%20sector%20-%20IGC.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className={supClass}
                >
                  [5]
                </a>
              </sup>{" "}
              ), Rickshaw pullers
              <sup>
                <a
                  href="https://link.springer.com/chapter/10.1007/978-981-13-1537-4_12"
                  target="_blank"
                  rel="noreferrer"
                  className={supClass}
                >
                  [6]
                </a>
              </sup>
              , Construction sector
              <sup>
                <a
                  href="http://www.rmmru.org/newsite/wp-content/uploads/2021/09/The-Construction-Precariat-Dependence-Domination-and-Labour-in-Dhaka.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className={supClass}
                >
                  [7]
                </a>
              </sup>
            </TextBox>
            <TextBox>
              The Global Index Risk Report (2021)
              <sup className={supClass}>
                {/* <a
                  href="The report assessed the most impacted countries due to extreme weather. "
                  target="_blank"
                  rel="noreferrer"
                  className={supClass}
                > */}
                [8]
                {/* </a> */}
              </sup>{" "}
              placed Bangladesh at <strong>Rank 13</strong>
              <sup>
                <a
                  href="https://www.germanwatch.org/en/19777"
                  target="_blank"
                  rel="noreferrer"
                  className={supClass}
                >
                  [9]
                </a>
              </sup>
              .
              <br />
              <br />
              <span>
                a. <strong>Climate migrants in Bangladesh (prediction)</strong>:
                13.3 million internal migrants by 2050 as a result of climate
                change
                <sup>
                  <a
                    href="https://www.worldbank.org/en/news/feature/2022/10/31/key-highlights-country-climate-and-development-report-for-bangladesh"
                    target="_blank"
                    rel="noreferrer"
                    className={supClass}
                  >
                    [10]
                  </a>
                </sup>
              </span>
            </TextBox>
            <p className="text-white text-sm">
              For more on internal migration in India and other countries in
              South and Southeast Asia, read our report{" "}
              <a
                href="/internal-migration-in-asia.pdf"
                target="_blank"
                rel="noreferrer"
                className={supClass + " text-white underline"}
              >
                Internal Migration in Asia
              </a>
              .
            </p>
          </div>
        </div>
        <div className="w-full h-full flex flex-col justify-center items-center">
          <img
            src="/images/Migration Map_Bangladesh.png"
            alt="migration map india"
            className="w-full h-full object-contain"
          />
          {/* <BangladeshMap />
          <div className="flex flex-row items-center gap-3">
            <MapLabelBox color="bg-[#ee6b6e]" title="MRC District" />
            <MapLabelBox color="bg-[#19bbf9]" title="MRC Districts" />
          </div> */}
        </div>
      </div>
    </div>
  );
}
const MapLabelBox = ({ title, color }) => {
  return (
    <div className="flex items-center gap-2">
      <div className={`${color} w-4 h-4`} />
      <p className="text-sm text-white font-body mb-0 leading-tight mt-[0.5px] font-semibold">
        {title}
      </p>
    </div>
  );
};
export const TextBox = (props) => {
  return (
    <div className="flex flex-row justify-start items-start transition-all ease duration-300 group gap-1 md:pb-4">
      {/* <BiSquareRounded className={IconClassName} /> */}

      <AiFillCheckCircle className="md:text-2xl text-xl text-blue shrink-0" />
      <Paragraph className={`${props.className} !text-white ml-2`}>
        {props.children}
      </Paragraph>
    </div>
  );
};
