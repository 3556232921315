import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Banner from "../components/Pages/Region/BannerBangladesh";
import MRC from "../components/Pages/Region/MRC";
import RegionMap from "../components/Pages/Region/RegionMap";
import Partners from "../components/Pages/Region/Partners";
import CaseStudy from "../components/Pages/Region/CaseStudy";
import AdditonalResources from "../components/Pages/Region/AdditonalResources";
import Insights from "../components/Pages/Region/Insights";
import { banglaInsights } from "../data/bangla-insights";
import { AiOutlineSolution } from "@react-icons/all-files/ai/AiOutlineSolution";
import { FaHandshake } from "@react-icons/all-files/fa/FaHandshake";

const BangladeshPage = () => {
  const IconClassName =
    "w-12 h-12 object-contain text-darkblue group-hover:text-black transition-all ease duration-300 shrink-0";
  const banglaPartners = [
    {
      link: "#",
      title: "Association for Community Development (ACD)",
      icon: "/images/bangladesh/Association for Community Development (ACD) logo.jpg",
    },
    {
      link: "http://bnsk.org/",
      title: "Bangladesh Nari Sramik Kendra (BNSK)",
      icon: "/images/bangladesh/Bangladesh Nari Sramik Kendra (BNSK) logo.png",
    },
    {
      link: "https://www.karmojibinari.org/",
      title: "Kormojibi Nari",
      icon: "/images/bangladesh/Kormojibi Nari (KN) logo.jpg",
    },
    {
      link: "https://www.okup.org.bd/",
      title: "Ovibashi Kormi Unnayan Program (OKUP)",
      icon: "/images/bangladesh/Ovibashi Kormi Unnayan Program (OKUP) logo.png",
    },
  ];
  const mrcContent = [
    {
      icon: <AiOutlineSolution className={IconClassName} />,
      description:
        "Developing scalable, context-appropriate models to improve last-mile access of social protection",
    },
    {
      icon: <FaHandshake className={IconClassName} />,
      description:
        "Collaborating with government and industry to build sustainable, systemic solutions to address challenges",
    },
  ];
  const learnMore = [
    {
      link: "https://cabinet.gov.bd/sites/default/files/files/cabinet.portal.gov.bd/research_corner/9dbddde7_4354_4f17_a256_1a444156b1aa/Determinants%20of%20Internal%20Migration%20Evidence%20from%20Bangladesh.pdf",
      text: "Determinants of Internal Migration: Evidence from Bangladesh",
    },
    {
      link: "https://link.springer.com/chapter/10.1007/978-981-13-1537-4_12",
      text: "Internal Migration, Urbanization and Poverty in Asia: Dynamics and Interrelationships",
    },
    {
      link: "https://www.rmmru.org/newsite/wp-content/uploads/2021/09/The-Construction-Precariat-Dependence-Domination-and-Labour-in-Dhaka.pdf",
      text: "The Construction Precariat: Dependence, Domination and Labour in Dhaka",
    },
  ];
  return (
    <Layout>
      <Seo
        title="MRC in Bangladesh"
        url="https://migrantresilience.org/bangladesh"
      />
      <Banner />
      <MRC
        heading={"MRC in Bangladesh"}
        description="MRC is working with local actors in Bangladesh to strengthen access to social protection for internal migrants and help build their resilience. MRC will support local actors in -"
        data={mrcContent}
      />
      <Partners
        title="Bangladesh CBO partners"
        data={banglaPartners}
        containerClass="lg:grid-cols-4 md:grid-cols-2 grid-cols-1"
      />
      <RegionMap
        title="Divisions MRC is active in"
        image="/images/Migration Map_Bangladesh2.png"
      />

      <Insights
        sectionHeading={"About internal migration in Bangladesh"}
        data={banglaInsights}
      />
      <CaseStudy
        title="Mohsin* - migrant working in food industry in Bangladesh"
        image="/images/bangladesh/case-story.jpg"
      >
        <p>
          “My father and I used to work on other’s land as labourers, we did not
          own any farming land. As my father grew older, it became difficult for
          him to carry out physically exhausting tasks in the field, making me
          the sole earner of my six-member household consisting of my parents,
          wife and two young children. The wages I earned from the field were
          only 30 Takas (3.43$) and was insufficient for the survival of my
          family. Over the years, even work available in the fields has become
          less frequent, especially since the majority of the land eroded
          because of the Padma River in my village. As a result, I started
          migrating about a decade ago. I work in the village for three to four
          months and then migrate to work at different potato cold storages in
          Paba sub-district, Rajshahi, Bogura, and Tangail districts
        </p>
        <p>
          I didn’t always work in cold storages, my first migration was with the
          help of a local contractor who took me to Dhaka and gave me a job as a
          construction worker. He promised to pay me a large sum of money.
          However, after the work was done, he cheated me. I returned to my
          village after 3 months and swore never to work in Dhaka city again.
          Since I hadn’t gotten paid for 3 months, the financial condition in my
          house became worse. A few villagers told me about the job
          opportunities available in a potato cold storage. From then on, I
          started migrating there as a factory worker with a group of ten to
          twelve other villagers.
        </p>
        <p>
          I earn Taka 8000 (91.39$) per month working in the factory. I wish to
          purchase land in the village, but it is impossible to do so with my
          city earnings, I do not have any savings as my income is completely
          consumed by household expenses. I don’t know if migration has been
          positive or negative for me but if I get money, I will work. It is my
          responsibility to provide enough food for my household and I will
          continue migrating.”
        </p>
        <p className="text-sm">
          *This story is anonymous and Mohsin is a pseudonym.
        </p>
      </CaseStudy>
      <AdditonalResources learnMore={learnMore} />
    </Layout>
  );
};
export default BangladeshPage;
